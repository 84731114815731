import React, { useEffect, useState, useContext } from "react"
import { graphql } from "gatsby"

import "../styles/pages/sunglasses.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import GlassesHeading from "../components/GlassesHeading"
import Breadcrumbs from "../components/Breadcrumbs"
import GlassesFilter from "../components/GlassesFilter"
import GlassesList from "../components/GlassesList"
import ProductBanner from "../components/ProductBanner"
import { useDebounce, useGlasses } from "../services/hooks"
import { navigateWithFilters } from "../services/utils"
import CartContext from "../context/cartContext"
import FAQBlock from "../components/FAQBlock"
import {
  sunglassesPcpFaq,

} from "../services/data"


const Sunglasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString, sunglassesAvailable }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern") &&
        sunglassesAvailable
      )
    }
  )

  const right_side_text =
    "Every pair of polarized sunglasses has impact-resistant lenses, durable barrel hinges, and padded temple tips for extra comfort."
  const list_items = [
    "Premium, hand-polished acetate frames sourced from natural fibers.  Every pair of our high-quality sunglasses feature impact-resistant lenses for maximum durability and are designed to deliver supreme comfort.",
    "Option to add your prescription to all sunglasses and enjoy both vision clarity and comfort all in one piece of eyewear.",
    "All of our sunglasses come with polarized lenses that block 99% of UVA/UVB rays and eliminate daytime glare.",
    "Affordable polarized sunglasses lenses.",
    "Hand-assembled in house and made to order.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: [],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    navigateWithFilters(location.pathname, debouncedFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'sunglasses',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Sunglasses}`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  useEffect(() => {
    setHeaderColor("green")

    return () => {
      setHeaderColor("black")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const schemaMarkup = [{
    "@context": "https://schema.org",
    "@url": "https://hubblecontacts.com/sunglasses",
    "@type": "webpage",
    "name": "Sunglasses: Prescription & Polarized Sunglasses Online | Hubble",
    "description": "Protect your eyes from the sun in style with our collection of prescription sunglasses. Our polarized sunglasses are available in a wide range of shapes and colors guaranteeing you'll find the perfect pair.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout>
      <SEO
        title="Sunglasses: Prescription & Polarized Sunglasses Online"
        description="Protect your eyes from the sun in style with our collection of prescription sunglasses. Our polarized sunglasses are available in a wide range of shapes and colors guaranteeing you'll find the perfect pair."
        schemaMarkup={schemaMarkup} />
      <div className="sunglasses-content">
        <GlassesHeading
          color="green"
          title="Prescription Sunglasses"
          description="Take on the sun in style with our selection of always classic frames and bold
                      and trendy shapes to complement any look. But you know what really makes them
                      shine? You can add your prescription to every single pair."
        />

        <div className="glasses-container">
          <div className="grid-section">
            <Breadcrumbs
              color="green"
              links={[{ to: "/", label: "Homepage" }, { label: "Sunglasses" }]}
            />
            <GlassesFilter
              type="sunglasses"
              glassesLength={sortedGlasses.length}
              filter={filter}
              setFilter={setFilter}
            />
            <GlassesList
              type="sunglasses"
              glasses={sortedGlasses}
              color={filter.color}
              gender={filter.gender[0]}
            />
            <ProductBanner
              title={"Online prescription sunglasses starting at $59.99."}
              right_side_text={right_side_text}
              list_items={list_items}
              optional_image="Hubble-Sunglasses-PCP-Inset-Lifestyle-Image.jpg"
              optional_image_alt_text={"Smiling man wearing Hubble sunglasses"}
              color="green"
            />
            <FAQBlock faqList={sunglassesPcpFaq} title="Buy Prescription Sunglasses Online: Frequently Asked Questions"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Sunglasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        sunglassesMaleHeaderImage1
        sunglassesFemaleHeaderImage1
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
