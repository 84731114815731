import React, { useState } from "react"
import "./style.scss"

const FAQBlock = ({ color, faqList, title, className }) => {
  return (
    <div className={`faq-wrapper ${className ? className : ""}`} data-color={color}>
      <div className="grid-section">
        <div className="heading-block">
          <h4 className="text h9 neue-bold">FREQUENTLY ASKED QUESTIONS</h4>
          <h3 className="text h4">{title != null ? title : "Got questions? We've got answers." }</h3>
        </div>
        <div className="faq-block">
          {faqList.map(({ question, answer }, index) => (
            <FAQItem
              question={question}
              answer={answer}
              isActive={index === 0}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const FAQItem = ({ question, answer, isActive }) => {
  const [active, setActive] = useState(isActive)

  return (
    <div className="faq-item">
      <button className="question" onClick={() => setActive(!active)}>
        <p className="text h7 neue-bold">{question}</p>
        <i className="toggler" data-active={active} />
      </button>
      {active && (
        <div
          className="answer text h7"
          dangerouslySetInnerHTML={{ __html: answer }}
        />
      )}
    </div>
  )
}

export default FAQBlock
